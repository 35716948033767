<template>
  <div>
    <ClientHeader :client="client" />
    <v-col class="px-0" cols="12" id="payroll-csv">
      <v-card class="mx-auto" link outlined @click.native="openClientProfileUploadCSVDialog">
        <v-list-item three-line>
          <v-list-item-content>
            <v-list-item-title class="text-h5 mb-6 font-weight-bold">
              Payroll CSV
            </v-list-item-title>

            <v-list-item-subtitle class="mb-4">
              <span v-if="!dataLoaded">Loading...</span>
              <span v-else-if="!client.csv_upload.id"> No CSV uploaded </span>
              <span v-else>
                CSV uploaded: <br />
                {{ client.csv_upload.original_file_name }}
              </span>
            </v-list-item-subtitle>

            <v-spacer></v-spacer>

            <v-card-actions>
              <v-btn text color="#1867C0" @click.stop="openClientProfileUploadCSVDialog">
                <u> Upload CSV </u>
              </v-btn>
            </v-card-actions>
          </v-list-item-content>
        </v-list-item>
      </v-card>
    </v-col>
    <v-col class="px-0" cols="12" id="owners-list">
      <v-card class="mx-auto" link outlined @click.native="openSelectOwnersDialog">
        <v-list-item three-line>
          <v-list-item-content>
            <v-list-item-title class="text-h5 mb-6 font-weight-bold">
              Owners List
            </v-list-item-title>

            <v-row>
              <v-col
                cols="6"
                style="padding-top: 5px; !important; padding-bottom: 5px;!important;"
                v-for="(owner, index) in owners"
                :key="'owner' + index"
              >
                <span>{{ owner }} </span>
              </v-col>
            </v-row>

            <v-list-item-subtitle v-if="owners.length === 0 && hasOwners">
              You have declared the uploaded CSV file does not contain any owner or family records
            </v-list-item-subtitle>

            <v-list-item-subtitle v-else-if="owners.length === 0">
              Upload a payroll CSV so you can select owners
            </v-list-item-subtitle>

            <v-list-item-subtitle class="mb-4"></v-list-item-subtitle>
            <v-spacer></v-spacer>

            <v-card-actions>
              <v-btn text color="#1867C0" @click.stop="openSelectOwnersDialog">
                <u> Edit owners</u>
              </v-btn>
            </v-card-actions>
          </v-list-item-content>
        </v-list-item>
      </v-card>
    </v-col>

    <v-row>
      <v-col cols="12">
        <v-card class="mx-auto" outlined>
          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item-title class="text-h5 font-weight-bold">
                <div class="d-flex justify-space-between align-center">
                  <span>ERC refund totals </span>

                  <v-switch
                    v-model="isErcRefundDisabled"
                    color="#48A64C"
                    class="switchDraftOrFinal"
                    hide-details
                  />
                </div>
              </v-list-item-title>

              <v-row>
                <v-col
                  cols="3"
                  v-for="(refund, index) in ercRefundTotals"
                  :key="'erc-refunds' + index"
                >
                  <v-text-field
                    v-model="refund.refund_amount"
                    required
                    persistent-hint
                    :hint="hints[index]"
                    color="black"
                    type="string"
                    :disabled="!isErcRefundDisabled"
                  />
                </v-col>
              </v-row>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <v-row class="mt-16 d-flex justify-center">
      <div class="text-center" v-if="checkItems.length < 1">
        <v-divider class="mb-6" />
        <h3 class="text-center mb-2">
          <span class="pb-4">There are no current receivables for this client.</span>
        </h3>
        <br />
        Receivables are generated when a signed return is uploaded to the submissions tab, and a
        tracking number is entered.
        <p>
          If this has already been done, contact
          <a href="mailto:support@patriotdataprocessing.com">support</a>.
        </p>
      </div>
      <div v-if="checkItems.length > 0" style="width: 100%">
        <v-card id="receiveCheckCard" class="mx-3">
          <h3 class="text-start pt-5 ml-3">Receive a check</h3>
          <div class="ma-3 d-flex flex-row">
            <v-text-field
              v-model="checkNumber"
              label="Check number"
              class="mr-2 mt-3"
            ></v-text-field>
            <v-text-field
              v-model="checkAmount"
              v-restrict.number.decimal
              prefix="$"
              label="Check amount"
              class="ml-2 mt-3"
            ></v-text-field>

            <v-menu
              v-model="menu"
              :close-on-content-click="false"
              :nudge-left="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  class="mt-3"
                  v-model="dateFormatted"
                  label="Issued date"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="issuedDate" @input="menu = false"></v-date-picker>
            </v-menu>
            <v-btn
              @click="postChecks(checkNumber, checkAmount)"
              :disabled="!enabledReceiveCheck"
              color="#263B4F"
              class="mt-5 ml-5"
              ><span style="color: #fff">Receive</span></v-btn
            >
          </div>
        </v-card>
        <h3
          :class="{ marginIfNoDeductable: !showDeductable }"
          class="mt-8 text-center"
          v-if="totalBalance !== null"
        >
          Balance to be applied:
          <span :class="{ redBalance: showDeductable }">{{ $formatAmount(totalBalance) }}</span>
        </h3>
        <h4
          :class="{ shake: showDeductable }"
          id="animated-number-demo"
          v-show="showDeductable"
          style="margin-left: 160px; color: #d21919; z-index: 999"
        >
          - {{ deductableAmount }} $
        </h4>
        <v-btn
          class="mt-6"
          color="#263B4F"
          @click="writeOffBalance"
          v-show="totalBalance <= 1 && totalBalance >= 0.01 && totalBalance !== 0"
        >
          <span style="color: #ffffff">Write off</span></v-btn
        >
        <div v-show="successMessage" class="container" v-html="successMessage"></div>

        <v-col v-for="(item, index) in checkItems" :key="index">
          <v-row>
            <v-col cols="12">
              <div
                style="
                  border-radius: 15px;
                  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%),
                    0px 1px 5px 0px rgb(0 0 0 / 12%);
                "
                class="mt-8"
                :class="{ activeCheck: item.balance == 0 }"
                @click="distributeBalance(item, index)"
              >
                <v-card-title>Quarter: {{ item.quarter }}</v-card-title>
                <v-row>
                  <v-col cols="6" class="text-start pl-6">
                    <h3 class="checkFees">Fee expected: {{ item.fee_expected_formatted }}</h3>
                    <h3 class="checkFees">
                      Fees received: {{ $formatAmount(item.fees_received) }}
                    </h3>
                    <h3
                      :class="{
                        validBalance: item.balance == 0,
                        inValidBalance: item.balance !== 0,
                      }"
                    >
                      Balance: {{ $formatAmount(item.balance) }}
                      <span
                        :class="{ shake: showAdding[index] }"
                        id="animated-number-demo"
                        v-show="showAdding[index]"
                        style="margin-left: 2px; color: #d21919; z-index: 999"
                        >- {{ $formatAmount(deductableAmountPerCheck[index]) }}</span
                      >
                    </h3>
                  </v-col>
                  <v-col cols="6">
                    <div
                      class="d-flex flex-column align-start"
                      v-for="(payment, payIndex) in item.applied_payments"
                      :key="payIndex"
                    >
                      <h3 style="font-size: 12px; font-weight: 400" class="checkFees">
                        Applied {{ payment.amount_formatted }} from Check
                        {{ payment.check_number }}
                      </h3>
                      <h3
                        style="font-size: 12px; font-weight: 400"
                        v-if="payment.check_issue_date"
                        class="checkFees"
                      >
                        on {{ payment.check_issue_date }}
                      </h3>
                    </div>
                  </v-col>
                </v-row>
                <v-divider v-show="item.balance < 0"></v-divider>
                <h3
                  class="px-3 pt-2"
                  style="color: #af0808; font-weight: 400"
                  v-show="item.balance < 0"
                >
                  This quarter has a negative balance, and may require a refund be issued to the
                  client. Refunds must be manually applied by contacting support.
                </h3>
              </div>
            </v-col>
          </v-row>
          <v-divider></v-divider>
        </v-col>
        <div v-if="totalBalance < 1">
          <v-btn color="#263B4F" :disabled="!activateApplyButton" @click="postCheck" class="mt-8">
            <span style="color: #fff">Apply payment</span>
          </v-btn>
        </div>
        <div v-else>
          <v-btn color="#263B4F" class="mt-8" @click="postCheck">
            <span style="color: #fff">Overpayment</span>
          </v-btn>
        </div>
        <div class="ma-5">
          <div v-if="overpayment === true">
            <p style="font-size: 1rem; color: black">
              "There is an existing overpayment that needs to be returned".
            </p>
            <p style="font-size: 1.1rem; color: red">${{ overpaymentBalance }}</p>
            <span @click="setOverpayment()" style="font-size: 1rem; color: #0f528e; cursor: pointer"
              >Go to the overpayment.</span
            >
          </div>
          <!-- <h4 v-show="!activateApplyButton" style="color: #D21919">"Balance to be applied" must be $0.00 before you can apply this payment.</h4> -->
          <h4 v-show="activateApplyButton" style="color: #017806">
            "Balance to be applied is zero. Click Apply payment to save and apply this payment".
          </h4>
        </div>
      </div>

      <v-overlay :value="loadingOnPost">
        <v-progress-circular
          :size="70"
          :width="7"
          color="white"
          indeterminate
        ></v-progress-circular>
      </v-overlay>
    </v-row>

    <ClientDragAndDropCSVFile
      :key="keyUploadCSV + '-csv'"
      :showRightDialog.sync="showRightDialogUploadCSV"
      :clientIdProp="this.client.id"
      @getDataForOwnersDialog="getAllOwners"
      @getUpdatedInfo="getUpdatedInfo"
    />
    <OwnerSelectDialog
      :key="showOwnersSelectDialogKey + '-owners'"
      :showRightDialog.sync="showOwnersSelectDialog"
      :ownersProp="employees"
      :hasOwnersProp="hasOwners"
      :titleRoundProp="'Select Owners'"
      :saveLoading="saveOwnersLoading"
      @saveOwners="saveOwners"
    />
  </div>
</template>

<script>
import OwnerSelectDialog from "@/components/OwnerSelectDialog.vue";
import ClientDragAndDropCSVFile from "@/components/Client/ClientDragAndDropCSVFile.vue";
import OwnersService from "@/services/owners.service";
import ClientService from "@/services/clients.service";
import { mapGetters } from "vuex";
import ClientHeader from "@/components/Client/ClientHeader.vue";

export default {
  name: "client-admin",
  components: {ClientHeader, ClientDragAndDropCSVFile, OwnerSelectDialog },
  data: () => ({
    progressItems: [
      { name: "Client Service Agreement (CSA)", collected: true, na: true },
      { name: "Financial Checklist", collected: false, na: true },
      { name: "Form 3508 - PPP 1", collected: false, na: true },
      { name: "Form 3508 - PPP 2", collected: false, na: true },
      { name: "941 - 2020 Q4", collected: false, na: true },
      { name: "941 - 2021 Q1", collected: false, na: true },
      { name: "941 - 2021 Q2", collected: false, na: true },
      { name: "941 - 2021 Q3", collected: false, na: true },
      { name: "Raw Payroll - Q1 2020", collected: false, na: true },
      { name: "Raw Payroll - Q2 2020", collected: false, na: true },
      { name: "Raw Payroll - Q3 2020", collected: false, na: true },
      { name: "Raw Payroll - Q4 2020", collected: false, na: true },
      { name: "Raw Payroll - Q1 2021", collected: false, na: true },
      { name: "Raw Payroll - Q2 2021", collected: false, na: true },
      { name: "Raw Payroll - Q3 2021", collected: false, na: true },
    ],
    progressHeader: [
      {
        align: "start",
        sortable: false,
        value: "name",
      },
      {
        text: "Collected",
        sortable: false,
        value: "collected",
      },
      {
        text: "NA",
        sortable: false,
        value: "na",
      },
    ],
    progresBtnLoading: false,
    checkItems: [],
    creditExpected: "",
    //test
    showRightDialogUploadCSV: false,
    keyUploadCSV: 0,
    owners: [],
    hasOwners: false,
    isErcRefundDisabled: false,
    hints: ["2020", "2021-Q1", "2021-Q2", "2021-Q3"],
    ercRefundTotals: [
      { refund_amount: null },
      { refund_amount: null },
      { refund_amount: null },
      { refund_amount: null },
    ],
    showOwnersSelectDialog: false,
    saveOwnersLoading: false,
    showOwnersSelectDialogKey: 0,
    employees: null,
    isOwnerPresented: true,
    issuedDate: "",
    menu: false,
    //
    balances: [],
    checkNumber: "",
    checkAmount: "",
    totalBalance: null,
    deductableAmount: 100,
    deductableAmountPerCheck: [],
    showDeductable: false,
    showAdding: [],
    showDeductableAmount: [],
    hasSolvesMatches: false,
    isCheckPosted: false,
    finalAmount: 0,
    finalCheckNumber: null,
    checkId: null,
    loadingOnPost: false,
    successMessage: null,
    writeOff: 0,
    dateFormatted: "",
    overpayment: false,
    overpaymentBalance: null,
    client: {},
    dataLoaded: false,
  }),
  methods: {
    writeOffBalance() {
      this.writeOff = this.totalBalance;
      this.totalBalance = 0;
    },
    // formatPrice(amount){
    //   const formatter = new Intl.NumberFormat('en-US', {
    //     style: 'currency',
    //     currency: 'USD',
    //   });
    //   return formatter.format(amount)
    // },
    openSelectOwnersDialog() {
      this.showOwnersSelectDialog = true;
      this.showOwnersSelectDialogKey++;
    },
    openClientProfileUploadCSVDialog() {
      this.showRightDialogUploadCSV = true;
      this.keyUploadCSV++;
    },
    getUpdatedInfo() {
      this.$emit("getCurrentClient", this.client.id);
    },
    getAllOwners() {
      return OwnersService.getOwners({ clientId: this.client.id })
        .then((data) => {
          this.owners = data.owners;
          this.hasOwners = data.has_owners;
          this.emitOwnersToProfileDialog();
        })
        .catch((error) => {
          this.$func.popMessage(`${error.response.data.error}`, "fail");
        });
    },
    checkOverpayment() {
      return ClientService.getRefundedOverpaymentsStatus(this.client.id)
        .then((data) => {
          this.overpayment = data.result;
          this.overpaymentBalance = data.balance.slice(1);
        })
        .catch((error) => {
          if (!this.openAdminTab) {
            this.$func.popMessage(`${error.response.data.error}`, "fail");
          }
        });
    },
    emitOwnersToProfileDialog() {
      this.$emit("getOwners", this.hasOwners);
    },
    async saveOwners(ownersData) {
      this.saveOwnersLoading = true;

      await OwnersService.deleteOwner({ clientId: this.client.id })

      if (ownersData.length) {
        await OwnersService.createOwner(this.client.id, ownersData)
      }

      this.$store.dispatch("snackbar/showSnackbarMessage", {
        message: `Owners updated successfully`,
        duration: 4000,
        mode: "success",
      });

      this.showOwnersSelectDialog = false
      this.saveOwnersLoading = false;
      this.getAllOwners();
      this.getAllEmployees();

    },
    getAllEmployees() {
      return OwnersService.getAllEmployees({ clientId: this.client.id }).then((data) => {
        this.employees = data;
        this.isOwnerPresented = this.employees.some((employee) => employee.owner === 1);
      });
    },
    getCurrentRefunds() {
      return ClientService.getRefunds(this.client.id)
        .then((data) => {
          if (data.length === 0) {
            this.ercRefundTotals = [
              { refund_total: 0 },
              { refund_total: 0 },
              { refund_total: 0 },
              { refund_total: 0 },
            ];
          } else {
            this.ercRefundTotals = data;
          }
        })
        .catch((error) => {
          this.rounds = [];
          this.$func.popMessage(`${error.response.data.error}`, "fail");
        });
    },
    getChecks() {
      this.loadingOnPost = true;
      ClientService.getChecks(this.clientId)
        .then((res) => {
          this.checkItems = res;

          // this.checkItems.map((item, index) => {
          //   this.balances.push(item.fee_expected)
          // })
          this.checkItems.map((item) => {
            //item.creditExpected = item.fee_expected
            item.balance = item.fee_expected;
            item.balance -= item.fees_received;
            this.checkIfBalanceIsPositive(item.balance);
            item.initial_fee_recieved = item.fees_received;
            // item.fee_recieved = 0
            this.showAdding.push(false);
            this.deductableAmountPerCheck.push(0);
          });
          this.loadingOnPost = false;
          setTimeout(() => {
            let y = document.getElementById("receiveCheckCard");
            if (this.openAdminTab && y !== null) {
              y.classList.replace("theme--light", "lightThemeReplacement");
            }
          }, 100);
        })
        .catch((err) => {
          this.loadingOnPost = false;
          this.$func.popMessage(err, "danger");
        });
    },
    postChecks(checkId, data) {
      const model = {
        check_number: checkId,
        amount: data,
      };
      this.finalCheckNumber = checkId;
      this.finalAmount = data;
      ClientService.editCheck(this.clientId, model).then((res) => {
        this.isCheckPosted = true;
        const filteredCheckAmount = data.replace(",", "");

        this.totalBalance = parseFloat(filteredCheckAmount).toFixed(2);
        this.successMessage = res.message;
        // this.getChecks()
        if (res.match) {
          this.hasSolvesMatches = true;
          res.quarters.forEach((q) => {
            let checkIndex = null;
            this.checkItems.forEach((e, index) => {
              if (e.quarter === q) {
                checkIndex = index;
              }
            });
            this.checkItems[checkIndex].balance = 0;
            this.checkItems[checkIndex].fees_received = this.checkItems[checkIndex].fee_expected;
            this.checkItems[checkIndex].amount_spent = this.checkItems[checkIndex].fee_expected;
            this.checkItems[checkIndex].isMatched = true;
          });
          this.totalBalance = 0;
        }
        this.$func.popMessage(`Check received successfully`, "success");
      });
    },

    warnDisabled(index) {
      this.showDeductable = true;
      this.showAdding[index] = true;
      setTimeout(() => {
        this.showDeductable = false;
        this.showAdding.map((e, i) => {
          this.showAdding[i] = false;
        });
      }, 1000);
    },

    distributeBalance(check, index) {
      if (this.isCheckPosted) {
        if (this.totalBalance > 0 && check.balance !== 0) {
          this.validCheckClicked(check, index);
        } else if (check.isMatched) {
          this.matchedCheckClicked(check, index);
        }
      } else {
        this.$func.popMessage("Receive a check to continue!", "danger");
      }
    },
    checkIfBalanceIsPositive(balance) {
      if (balance < 0) {
        this.$func.popMessage(
          "This quarter has a negative balance, and may require a refund be issued to the client. Refunds must be manually applied by contacting support.",
          "danger"
        );
      }
    },
    validCheckClicked(check, index) {
      if (check.balanceAdded) {
        this.checkItems[index].fees_received = this.checkItems[index].initial_fee_recieved;
        this.checkItems[index].balance =
          this.checkItems[index].fee_expected - this.checkItems[index].initial_fee_recieved;
        this.totalBalance += parseFloat(this.checkItems[index].fee_expected);
        this.checkItems[index].amount_spent = this.checkItems[index].initial_fee_recieved;
        this.checkItems[index].balanceAdded = false;
      } else {
        this.deductableAmount = parseFloat(this.totalBalance).toFixed(2);
        this.deductableAmountPerCheck[index] = parseFloat(this.totalBalance).toFixed(2);
        if (check.balance < this.totalBalance) {
          this.deductableAmount = parseFloat(check.balance).toFixed(2);
          this.deductableAmountPerCheck[index] = parseFloat(check.balance).toFixed(2);
        }

        this.checkItems[index].fees_received =
          parseFloat(this.checkItems[index].fees_received) + parseFloat(this.deductableAmount);
        this.checkItems[index].amount_spent = this.deductableAmount;
        this.totalBalance -= this.deductableAmount;
        // this.balances[index] -= this.deductableAmount
        this.checkItems[index].balance =
          parseFloat(this.checkItems[index].balance) - this.deductableAmount;
        this.checkItems[index].balanceAdded = true;
        this.checkIfBalanceIsPositive(this.checkItems[index].balance);

        //Test

        ///
        this.warnDisabled(index);
        this.checkItems = [...this.checkItems];
      }
    },
    matchedCheckClicked(check, index) {
      this.checkItems[index].fees_received = this.checkItems[index].initial_fee_recieved;
      this.checkItems[index].amount_spent = this.checkItems[index].initial_fee_recieved;
      this.checkItems[index].balance = this.checkItems[index].fee_expected;
      this.totalBalance += parseFloat(this.checkItems[index].fee_expected);
      this.checkItems[index].isMatched = false;
    },
    applyFinalPayment() {
      const finalChecks = [];
      this.checkItems.forEach((e) => {
        if (e.balanceAdded || e.isMatched) {
          const model = {
            credit_id: e.credit_id,
            amount: e.amount_spent,
          };
          finalChecks.push(model);
        }
      });
      const paymentModel = {
        payment_id: this.checkId,
        payment_applications: finalChecks,
        writeoff: this.writeOff <= 0.01 ? 0 : this.totalBalance,
      };
      ClientService.applyPayment(this.clientId, paymentModel)
        .then((res) => {
          this.totalBalance = null;
          this.loadingOnPost = false;
          {
            this.$func.popMessage("Your payment has been successfully applied!", "success");
          }
          this.checkAmount = "";
          this.checkNumber = null;
          this.successMessage = "";
          this.getChecks();
        })
        .catch((err) => {
          {
            this.loadingOnPost = false;
            this.$func.popMessage(res.error, "danger");
          }
        });
    },
    postCheck() {
      this.loadingOnPost = true;
      const model = {
        check_number: this.finalCheckNumber,
        amount: this.finalAmount,
        check_issue_date: this.issuedDate,
      };
      ClientService.postCheck(this.clientId, model)
        .then((res) => {
          this.checkId = res.id;
          this.applyFinalPayment();
        })
        .catch((err) => {
          this.loadingOnPost = false;
          this.$func.popMessage(err, "danger");
        });
    },
    getCurrentClient(id) {
      return ClientService.getOneClient(id)
        .then((data) => {
          this.client = data;
          this.dataLoaded = true;
        })
        .catch((error) => {
          this.$store.dispatch("snackbar/showSnackbarMessage", {
            message: `${error.response.data.error}. Please, try again.`,
            duration: 4000,
            mode: "fail",
          });
        });
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${month}/${day}/${year}`;
    },
    setOverpayment() {
      this.$router.push("/overpayments");
      return this.$store.dispatch("payments/changeOverpayment", {
        number: 4,
      });
    },
  },
  created() {
    this.clientId = this.$route.params.clientId;
    this.getCurrentClient(this.clientId)
      .then(() => {
        // After getting the client, execute the other methods
        this.checkOverpayment();
        this.getAllOwners();
        this.getCurrentRefunds();
        this.getAllEmployees();
        this.getChecks();
      })
      .catch((error) => {
        // Handle any errors that may occur while fetching the client
        console.error(error);
      });
  },
  computed: {
    ...mapGetters({
      openAdminTab: "tour/getOpenAdminTab",
    }),
    computedDateFormatted() {
      return this.formatDate(this.date);
    },
    activateApplyButton() {
      let valid = false;
      if (this.totalBalance <= 0.1 && this.totalBalance !== null) {
        valid = true;
      }
      return valid;
    },
    enabledReceiveCheck() {
      return this.checkNumber && this.checkAmount && this.issuedDate;
    },
  },
  watch: {
    issuedDate(val) {
      this.dateFormatted = this.formatDate(this.issuedDate);
    },
    showRightDialogUploadCSV(val) {
      if (val === false) {
        // this.getAllOwners()
        this.getCurrentClient(this.clientId);
        this.getAllEmployees();
      }
    },
  },
};
</script>

<style scoped>
.checkFees {
  font-weight: 350;
  font-size: 16px;
}

.validBalance {
  color: #006a05;
}

.inValidBalance {
  color: #d21919;
}

.marginIfNoDeductable {
  margin-bottom: 22px;
}

.activeCheck {
  background-color: #48a64c40;
}

.shake {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
}

@keyframes shake {
  60% {
    opacity: 0.9;
  }
}

.redBalance {
  color: #d21919;
}

.v-tour__target--highlighted {
  box-shadow: 0 0 0 99999px rgba(0, 0, 0, 0.7) !important;
}
</style>
